import { PropsWithChildren } from 'react';

export default function TableHeaderCell({
  children,
  small,
  textAlign,
}: PropsWithChildren & {
  small?: boolean;
  textAlign?: 'left' | 'center' | 'right';
}) {
  const padding = small ? 'px-2 py-3.5' : 'px-3 py-3.5';
  const align =
    textAlign === 'left'
      ? 'text-left'
      : textAlign === 'right'
        ? 'text-right'
        : 'text-center';

  return (
    <th
      scope="col"
      className={`whitespace-nowrap ${padding} ${align} text-sm font-semibold text-gray-900`}
    >
      {children}
    </th>
  );
}
