import { Navigate } from 'react-router-dom';
import { useEffect } from 'react';
import { CurrentUser, useAuthenticatedUser } from '../auth';
import { Spinner } from './loading';

export default function AbstractSubscriptionCallbackPage({
  description,
  expectedSubscriptionStatus,
}: {
  description: string;
  expectedSubscriptionStatus: CurrentUser['status']['subscription'];
}) {
  const { currentUser, refreshCurrentUser } = useAuthenticatedUser();

  useEffect(() => {
    if (currentUser.status.subscription === expectedSubscriptionStatus) {
      return;
    }

    const intervalId = setInterval(async () => {
      await refreshCurrentUser();
    }, 1000);

    // Clear the interval when the component unmounts and before every re-render when current user changes.
    return () => clearInterval(intervalId);
  }, [currentUser, refreshCurrentUser, expectedSubscriptionStatus]);

  if (currentUser.status.subscription === expectedSubscriptionStatus) {
    return <Navigate to="/account-settings" replace />;
  }

  return (
    <div className="flex min-h-full flex-col items-center justify-center space-y-4">
      <Spinner />
      <div className="text-tremor-default text-tremor-content">{description}</div>
    </div>
  );
}
