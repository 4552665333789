import { User } from '../../../../requests/api/back-office.requests';

export default function AccountStatus({ status }: { status: User['status']['account'] }) {
  if (status === 'signed-up') {
    return <span className="text-red-600">Not synchro</span>;
  }

  if (status === 'ga4-connected') {
    return <span className="text-green-600">Synchro</span>;
  }

  return null;
}
