import Spinner from './Spinner';
import { RiErrorWarningLine } from '@remixicon/react';

export default function LoadingPage({ hasError }: { hasError?: boolean }) {
  return (
    <div className="flex min-h-full items-center justify-center">
      {hasError ? (
        <div className="flex flex-col gap-y-3 rounded-lg bg-white px-9 py-7 text-gray-700 shadow">
          <RiErrorWarningLine
            className="mx-auto h-7 w-7 text-gray-600"
            aria-hidden="true"
          />
          An error has occurred. Unable to load page.
        </div>
      ) : (
        <Spinner />
      )}
    </div>
  );
}
