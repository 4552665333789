// is enabled

import { ExternalService } from './external-service.interface';

const GOOGLE_ANALYTICS_PROPERTY_ID = process.env.REACT_APP_GOOGLE_ANALYTICS_PROPERTY_ID;

export const productAnalytics = {
  name: 'Google Analytics',
  isEnabled() {
    return GOOGLE_ANALYTICS_PROPERTY_ID !== undefined;
  },
  init() {
    // Google Analytics is initialized in the <head> of the HTML document
  },
  setUser(user: { id: number; email: string } | null) {
    if (!user) {
      console.log('Resetting user data for product analytics');
      // gtag('set', 'user_properties', {
      //   user_id: null
      // });

      return;
    }

    console.log('Identifying user: ', user.id);
    // gtag('set', 'user_properties', {
    //   user_id: user.id
    // });
  },
  sendEvent(name: string, data: Record<string, unknown> = {}): void {
    console.log('Sending event: ', name, data);
    try {
      // gtag('event', name, data);
    } catch (error) {
      console.error('Error sending event to Google Analytics', error);
    }
  },
} satisfies ExternalService;
