import { BarList, Callout, Card } from '@tremor/react';
import { useBlock } from '../common';
import { useShowMorePagination } from '../../../../utils';
import { Button, CardHeader, SkeletonLoader } from '../../../../components';
import { DateRange, Filter } from '../../../../requests/api/traffic.requests';

import { useState } from 'react';

export type LocationsBlockName = 'countries' | 'regions' | 'cities' | 'languages';

const singularTabs: Record<LocationsBlockName, string> = {
  countries: 'Country',
  regions: 'Region',
  cities: 'City',
  languages: 'Language',
};

const pluralTabs: Record<LocationsBlockName, string> = {
  countries: 'Countries',
  regions: 'Regions',
  cities: 'Cities',
  languages: 'Languages',
};

interface LocationsCardProps {
  websiteId: string;
  dateRange: DateRange;
  filters: Filter[];
  onInitialLoadingComplete?: () => void;
}

export default function LocationsCard({
  websiteId,
  dateRange,
  filters,
  onInitialLoadingComplete,
}: LocationsCardProps) {
  const [blockName, setBlockName] = useState<LocationsBlockName>('countries');

  const block = useBlock({
    blockName,
    websiteId,
    dateRange,
    filters,
    queryKey: 'locationsBlock',
    onInitialLoadingComplete,
  });

  const { paginatedItems, showMoreItems, canMoreItemsBeShown } = useShowMorePagination({
    items: block.data?.items,
    maxItemsPerPage: 6,
  });

  return (
    <Card>
      <CardHeader
        title="Locations"
        tabs={pluralTabs}
        selectedTab={blockName}
        onSelectedTabChange={setBlockName}
      />
      <p className="mt-4 flex items-center justify-between text-tremor-default text-tremor-content dark:text-dark-tremor-content">
        <span>{singularTabs[blockName]}</span>
        <span>Visits</span>
      </p>
      {(block.isPending || block.isRefetching) && (
        <SkeletonLoader className="mt-2 h-36" />
      )}
      {block.isError && !block.isRefetching && (
        <Callout className="mt-4" title="An error has occurred" color="red">
          An error occurred while retrieving the data. Try refreshing the page.
        </Callout>
      )}
      {block.isSuccess && !block.isRefetching && (
        <>
          <BarList
            data={paginatedItems.map(({ name, visits }) => ({
              name,
              value: visits.value,
            }))}
            className="mt-2"
          />
          {canMoreItemsBeShown && (
            <div className="flex justify-center">
              <Button variant="light" onClick={showMoreItems}>
                Show more
              </Button>
            </div>
          )}
        </>
      )}
    </Card>
  );
}
