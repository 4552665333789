import { PropsWithChildren } from 'react';
import { Button as TremorButton } from '@tremor/react';

export default function Button({
  children,
  variant = 'neutral',
  icon: Icon,
  block,
  disabled,
  className,
  onClick,
}: PropsWithChildren & {
  variant?: 'primary' | 'neutral' | 'light' | 'danger';
  icon?: React.ElementType;
  block?: boolean;
  disabled?: boolean;
  className?: string;
  onClick: () => void;
}) {
  if (variant === 'neutral') {
    return (
      <button
        className={`${className ?? ''} ${block ? 'w-full' : ''} flex items-center justify-center space-x-1 rounded-md bg-white px-2.5 py-1.5 text-sm text-gray-700 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 disabled:cursor-not-allowed disabled:text-gray-300 disabled:hover:bg-inherit`}
        disabled={disabled}
        onClick={onClick}
      >
        {Icon && <Icon className="h-4 w-4" />}
        {children && <span>{children}</span>}
      </button>
    );
  }

  return (
    <TremorButton
      variant={
        variant === 'primary' ? 'primary' : variant === 'light' ? 'light' : 'secondary'
      }
      color={variant === 'danger' ? 'red' : undefined}
      icon={Icon}
      disabled={disabled}
      onClick={onClick}
      className={`${className ?? ''} ${block ? 'w-full' : ''}`}
    >
      {children}
    </TremorButton>
  );
}
