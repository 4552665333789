import { Link } from 'react-router-dom';
import { CardTitle, PageContainer } from '../../components';
import { Navbar } from './navbar';
import { useAuthenticatedUser } from '../../auth';
import { Card } from '@tremor/react';
import { getSubscriptionDescription } from '../common';
import { SectionContainer } from './section-container';

export default function MainPage() {
  const { currentUser } = useAuthenticatedUser();

  return (
    <PageContainer>
      <>
        <Navbar />
        {currentUser.status.canUseApp ? (
          <SectionContainer />
        ) : (
          <Card>
            <CardTitle title="Subscription" />
            <p className="mt-4 text-tremor-default leading-6 text-tremor-content">
              {getSubscriptionDescription({
                status: currentUser.status.subscription,
                subscription: currentUser.subscription,
              })}
            </p>
            <div className="mt-6">
              <Link
                to="/account-settings"
                className="rounded-md bg-blue-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600"
              >
                Subscribe
              </Link>
            </div>
          </Card>
        )}
      </>
    </PageContainer>
  );
}
