import { AbstractSubscriptionCallbackPage } from '../../components';

export default function SubscriptionCallbackPage() {
  return (
    <AbstractSubscriptionCallbackPage
      description="Your payment has been approved. Creating your subscription on Linito..."
      expectedSubscriptionStatus="active"
    />
  );
}
