import { ExternalService } from './external-service.interface';
import { liveChat } from './live-chat';
import { productAnalytics } from './product-analytics';

const externalServices: ExternalService[] = [liveChat, productAnalytics];

export function initExternalServices() {
  for (const service of externalServices) {
    if (!service.isEnabled()) {
      console.log(`${service.name} not enabled. Skipping initialization.`);
      continue;
    }

    try {
      service.init();
    } catch (error) {
      console.error(`Failed to initialize ${service.name}`, error);
    }
  }
}

export function setExternalServicesUser(user: { id: number; email: string } | null) {
  for (const service of externalServices) {
    if (!service.isEnabled()) {
      console.log(`${service.name} not enabled. Skipping user setup.`);
      continue;
    }

    try {
      service.setUser(user);
    } catch (error) {
      console.error(`Failed to set user for ${service.name}`, error);
    }
  }
}
