import { useAuth } from '../../auth';
import { useMakeAuthenticatedGetRequest } from '../requests.hooks';

export function useGetGa4ConnectURL(): () => Promise<string> {
  const { makeAuthenticatedGetRequest } = useMakeAuthenticatedGetRequest();

  return async () => {
    const { data } = await makeAuthenticatedGetRequest('/api/ga4/connect');
    return data.consentPageUrl;
  };
}

export function useHandleGa4ConnectRedirection(): ({
  searchParams,
}: {
  searchParams: string;
}) => Promise<void> {
  const { makeAuthenticatedGetRequest } = useMakeAuthenticatedGetRequest();
  const { refreshCurrentUser } = useAuth();

  return async ({ searchParams }) => {
    const { data } = await makeAuthenticatedGetRequest(
      `/api/ga4/connect/callback${searchParams}`,
    );
    await refreshCurrentUser();
    return data;
  };
}
