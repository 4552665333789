export default function Footer() {
  return (
    <footer className="flex justify-center space-x-4 pb-5 pt-4">
      <a
        href="https://linito.io/terms-and-conditions"
        className="text-center text-xs leading-5 text-gray-500"
      >
        Terms
      </a>
      <a
        href="https://linito.io/privacy"
        className="text-center text-xs leading-5 text-gray-500"
      >
        Privacy
      </a>
    </footer>
  );
}
