export function formatNumber(n: number, options?: { numberOfDecimals?: number }): string {
  const numberFormatter = new Intl.NumberFormat(
    process.env.REACT_APP_INTL_LANGUAGE,
    options
      ? {
          maximumFractionDigits: options.numberOfDecimals,
          minimumFractionDigits: options.numberOfDecimals,
        }
      : {},
  );

  const formattedNumber = numberFormatter.format(n);

  return formattedNumber;
}
