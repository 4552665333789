import { SectionNavbar } from '../common';
import { useOutletContext } from 'react-router-dom';
import { SectionContext } from '../section-container';
import { ConversionKeyMetricsCard } from './conversion-key-metrics-card';
import { ConversionCard } from './conversion-card';

export default function AcquisitionSection() {
  const { websiteId, websites, dateRange, setWebsiteId, setDateRange } =
    useOutletContext<SectionContext>();

  return (
    <div className="space-y-6">
      <SectionNavbar
        websites={websites}
        websiteId={websiteId}
        dateRange={dateRange}
        filters={[]}
        comparison={undefined}
        onWebsiteIdChange={setWebsiteId}
        onDateRangeChange={setDateRange}
        onFiltersChange={() => {}}
        onComparisonChange={() => {}}
      />
      <div className="grid grid-cols-4 gap-6">
        <div className="col-span-4 lg:col-span-1">
          <ConversionKeyMetricsCard
            websiteId={websiteId}
            dateRange={dateRange}
            filters={[]}
            comparison={undefined}
          />
        </div>
        <div className="col-span-4 lg:col-span-3">
          <ConversionCard
            websiteId={websiteId}
            dateRange={dateRange}
            filters={[]}
            comparison={undefined}
          />
        </div>
      </div>
    </div>
  );
}
