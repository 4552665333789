import { AbstractSubscriptionCallbackPage } from '../../components';

export default function ResubscriptionCallbackPage() {
  return (
    <AbstractSubscriptionCallbackPage
      description="Your recurring payment has been reactivated. Reactivating your subscription on Linito..."
      expectedSubscriptionStatus="active"
    />
  );
}
