import { Select, SelectItem } from '@tremor/react';
import { useEffect, useState } from 'react';
import Datepicker, { DateValueType } from 'react-tailwindcss-datepicker';
import { DateRange, ShortcutDateRange } from '../requests/api/traffic.requests';
import {
  formatDate,
  getDateFarInTheFuture,
  getDateThirtyDaysAgo,
  getNowDate,
} from '../utils';

export default function ExtendedDatePicker({
  className,
  value,
  onValueChange,
  isComparisonDatePicker,
}: {
  className?: string;
  value: DateRange;
  onValueChange: (value: DateRange) => void;
  isComparisonDatePicker?: boolean;
}) {
  const [selectValue, setSelectValue] = useState<
    // Type is actually string | undefined, but it's easier to understand this way.
    ShortcutDateRange['name'] | 'choosingCustom' | 'custom' | undefined
  >(undefined);

  useEffect(() => {
    setSelectValue(value.type === 'custom' ? 'custom' : value.name);
  }, [value]);

  const onDatePickerValueChange = (newValue: DateValueType) => {
    if (newValue === null || newValue.startDate === null || newValue.endDate === null) {
      return;
    }

    onValueChange({
      type: 'custom',
      startDate: newValue.startDate as string,
      endDate: newValue.endDate as string,
    });
  };

  const onSelectValueChange = (name: string) => {
    if (name === 'choosingCustom') {
      setSelectValue(name);
    } else {
      onValueChange({
        type: 'shortcut',
        name,
      });
    }
  };

  return (
    <div className="flex h-10 w-full items-center space-x-2">
      {selectValue === 'choosingCustom' && (
        <div className={className || ''}>
          <Datepicker
            placeholder="..."
            readOnly
            value={null}
            onChange={onDatePickerValueChange}
            // Around thirty days ago. There's no need to take the time zone into account.
            startFrom={new Date(getDateThirtyDaysAgo())}
            disabledDates={[
              {
                startDate: getNowDate(),
                endDate: getDateFarInTheFuture(),
              },
            ]}
          />
        </div>
      )}
      {selectValue !== 'choosingCustom' && (
        <Select
          className={className || ''}
          value={selectValue}
          onValueChange={name => onSelectValueChange(name)}
        >
          {isComparisonDatePicker && (
            <SelectItem value="samePeriod">Same period</SelectItem>
          )}
          {isComparisonDatePicker && (
            <SelectItem value="previousPeriod">Previous period</SelectItem>
          )}
          {isComparisonDatePicker && (
            <SelectItem value="samePeriodLastYear">Same period last year</SelectItem>
          )}
          {!isComparisonDatePicker && (
            <SelectItem value="yesterday">Yesterday</SelectItem>
          )}
          {!isComparisonDatePicker && (
            <SelectItem value="last7days">Last 7 days</SelectItem>
          )}
          {!isComparisonDatePicker && (
            <SelectItem value="last30days">Last 30 days</SelectItem>
          )}
          {!isComparisonDatePicker && (
            <SelectItem value="last12months">Last 12 months</SelectItem>
          )}
          <SelectItem value="choosingCustom">Custom range</SelectItem>
          <SelectItem value="custom" className="hidden">
            {value.type === 'custom' && formatDate({ date: value.startDate })}
            &nbsp;&nbsp;-&nbsp;&nbsp;
            {value.type === 'custom' && formatDate({ date: value.endDate })}
          </SelectItem>
        </Select>
      )}
    </div>
  );
}
