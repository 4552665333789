import { DevicesCard } from './devices-card';
import { LocationsCard } from './locations-card';
import { DistributionCard } from './distribution-card';
import { PagesCard } from './pages-card';
import { AcquisitionCard } from './acquisition-card';
import { TimeSeriesCard } from './time-series-card';
import { KeyMetricsCard } from './key-metrics-card';
import { SectionNavbar } from '../common';
import { useState } from 'react';
import { Comparison, Filter } from '../../../requests/api/traffic.requests';
import { Button, SkeletonLoader } from '../../../components';
import { useOutletContext } from 'react-router-dom';
import { SectionContext } from '../section-container';

function useInitialLoading({
  cardsRequiredToBeLoaded,
}: {
  cardsRequiredToBeLoaded: string[];
}) {
  const [loadedCards, setLoadedCards] = useState<string[]>([]);

  const markCardAsLoaded = (cardName: string) =>
    setLoadedCards(loadedCards => [...loadedCards, cardName]);

  const areAllRequiredCardsLoaded = cardsRequiredToBeLoaded.every(card =>
    loadedCards.includes(card),
  );

  return {
    areAllRequiredCardsLoaded,
    markCardAsLoaded,
  };
}

export default function TrafficSection() {
  /* Websites */
  const { websiteId, websites, dateRange, setWebsiteId, setDateRange } =
    useOutletContext<SectionContext>();

  /* Navbar */
  const [filters, setFilters] = useState<Filter[]>([]);

  const [comparison, setComparison] = useState<Comparison | undefined>(undefined);

  const { areAllRequiredCardsLoaded, markCardAsLoaded } = useInitialLoading({
    cardsRequiredToBeLoaded: ['keyMetrics', 'distribution'],
  });

  /* Cards */
  function onAddFilter(filter: Filter) {
    setFilters(filters => [...filters, filter]);
    setComparison(
      comparison =>
        comparison && {
          ...comparison,
          filters: [...comparison.filters, filter],
        },
    );
  }

  /* Show more section */
  const [isShowMoreSectionVisible, setIsShowMoreSectionVisible] = useState(false);
  const {
    areAllRequiredCardsLoaded: areAllRequiredShowMoreSectionCardsLoaded,
    markCardAsLoaded: markShowMoreSectionCardAsLoaded,
  } = useInitialLoading({
    cardsRequiredToBeLoaded: ['devices', 'locations'],
  });

  return (
    <section className="space-y-6">
      <SectionNavbar
        websites={websites}
        websiteId={websiteId}
        dateRange={dateRange}
        filters={filters}
        comparison={comparison}
        showComparison
        showFilters
        onWebsiteIdChange={setWebsiteId}
        onDateRangeChange={setDateRange}
        onFiltersChange={setFilters}
        onComparisonChange={setComparison}
      />
      {!areAllRequiredCardsLoaded && (
        <>
          <div className="flex space-x-6">
            <SkeletonLoader className="h-48 flex-1" />
            <SkeletonLoader className="h-48 flex-1" />
            <SkeletonLoader className="h-48 flex-1" />
          </div>
          <div className="flex space-x-6">
            <SkeletonLoader className="h-48 flex-1" />
            <SkeletonLoader className="h-48 flex-1" />
            <SkeletonLoader className="h-48 flex-1" />
          </div>
        </>
      )}
      <div className={'space-y-6' + (areAllRequiredCardsLoaded ? '' : ' hidden')}>
        <div className="grid grid-cols-7 gap-6">
          <div className="col-span-7 lg:col-span-5">
            <TimeSeriesCard
              websiteId={websiteId}
              dateRange={dateRange}
              filters={filters}
              comparison={comparison}
            />
          </div>
          <div className="col-span-7 lg:col-span-2">
            <KeyMetricsCard
              websiteId={websiteId}
              dateRange={dateRange}
              filters={filters}
              comparison={comparison}
              onInitialLoadingComplete={() => markCardAsLoaded('keyMetrics')}
            />
          </div>
        </div>
        <div className="grid grid-cols-11 gap-6">
          <div className="col-span-11 lg:col-span-3">
            <DistributionCard
              websiteId={websiteId}
              dateRange={dateRange}
              filters={filters}
              comparison={comparison}
              onInitialLoadingComplete={() => markCardAsLoaded('distribution')}
              onAddFilter={onAddFilter}
            />
          </div>
          <div className="col-span-11 lg:col-span-8">
            <AcquisitionCard
              websiteId={websiteId}
              dateRange={dateRange}
              filters={filters}
              comparison={comparison}
              onAddFilter={onAddFilter}
            />
          </div>
        </div>
        <div>
          <PagesCard
            websiteId={websiteId}
            dateRange={dateRange}
            filters={filters}
            comparison={comparison}
            onAddFilter={onAddFilter}
          />
        </div>
        {!isShowMoreSectionVisible ? (
          <div className="flex justify-center">
            <Button variant="light" onClick={() => setIsShowMoreSectionVisible(true)}>
              Show devices and locations
            </Button>
          </div>
        ) : (
          <>
            {!areAllRequiredShowMoreSectionCardsLoaded && (
              <div className="grid grid-cols-2 gap-6">
                <SkeletonLoader className="col-span-2 h-48 lg:col-span-1" />
                <SkeletonLoader className="col-span-2 h-48 lg:col-span-1" />
              </div>
            )}
            <div
              className={
                'grid grid-cols-2 gap-6' +
                (areAllRequiredShowMoreSectionCardsLoaded ? '' : ' hidden')
              }
            >
              <div className="col-span-2 lg:col-span-1">
                <DevicesCard
                  websiteId={websiteId}
                  dateRange={dateRange}
                  filters={filters}
                  onInitialLoadingComplete={() =>
                    markShowMoreSectionCardAsLoaded('devices')
                  }
                />
              </div>
              <div className="col-span-2 lg:col-span-1">
                <LocationsCard
                  websiteId={websiteId}
                  dateRange={dateRange}
                  filters={filters}
                  onInitialLoadingComplete={() =>
                    markShowMoreSectionCardAsLoaded('locations')
                  }
                />
              </div>
            </div>
          </>
        )}
      </div>
    </section>
  );
}
