import { Callout, Card } from '@tremor/react';
import {
  CardHeader,
  NumberValue,
  PaginationBar,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeaderCell,
  TableRow,
  SkeletonLoader,
} from '../../../../components';
import { useState } from 'react';
import { DateRange, Filter, Comparison } from '../../../../requests/api/traffic.requests';
import { usePagination } from '../../../../utils';
import { useBlock } from '../../traffic-section/common';

export type ConversionBlockName = 'sourceConversion';

const tabs: Record<ConversionBlockName, string> = {
  sourceConversion: 'Sources',
};

interface ConversionCardProps {
  websiteId: string;
  dateRange: DateRange;
  filters: Filter[];
  comparison: Comparison | undefined;
}

export default function ConversionCard({
  websiteId,
  dateRange,
  filters,
  comparison,
}: ConversionCardProps) {
  const [blockName, setBlockName] = useState<ConversionBlockName>('sourceConversion');
  const [maxItemsPerPage, setMaxItemsPerPage] = useState(10);

  const block = useBlock({
    blockName,
    websiteId,
    dateRange,
    filters,
    comparison,
    queryKey: 'conversionBlock',
  });

  const { paginatedItems, currentPageIndex, setCurrentPageIndex, numberOfPages } =
    usePagination({
      items: block.data?.items,
      maxItemsPerPage,
    });

  return (
    <Card>
      <CardHeader
        title="Conversion"
        isAboveTable
        tabs={tabs}
        selectedTab={blockName}
        onSelectedTabChange={setBlockName}
        maxItemsPerPage={maxItemsPerPage}
        onMaxItemsPerPageChange={setMaxItemsPerPage}
      />
      <div className="mt-2 overflow-x-auto">
        <Table>
          <TableHead>
            <TableRow>
              <TableHeaderCell small textAlign="left">
                Source
              </TableHeaderCell>
              <TableHeaderCell small textAlign="right">
                Converted <br />
                visitors
              </TableHeaderCell>
            </TableRow>
          </TableHead>
          {block.isSuccess && (
            <TableBody>
              {paginatedItems.map(item => (
                <TableRow key={item.name}>
                  <TableCell small textAlign="left" bold dark>
                    <span>
                      {block.isRefetching ? (
                        <SkeletonLoader hiddenText={item.name} className="inline" />
                      ) : (
                        item.name
                      )}
                    </span>
                  </TableCell>
                  <TableCell small textAlign="right">
                    <NumberValue
                      values={item.totalConvertedVisitors}
                      isLoading={block.isRefetching}
                    />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          )}
        </Table>
        {block.isSuccess && numberOfPages > 1 && (
          <div className="mt-2">
            <PaginationBar
              numberOfPages={numberOfPages}
              currentPageIndex={currentPageIndex}
              onCurrentPageIndexChange={setCurrentPageIndex}
            />
          </div>
        )}
        {block.isPending && <SkeletonLoader className="mt-2 h-36" />}
        {block.isError && (
          <Callout title="An error has occurred" color="red">
            An error occurred while retrieving the data. Try refreshing the page.
          </Callout>
        )}
      </div>
    </Card>
  );
}
