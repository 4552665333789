import { RiPlugLine } from '@remixicon/react';
import { Card } from '@tremor/react';
import { useGetGa4ConnectURL } from '../../../../requests/api/ga4.requests';
import { GOOGLE_ACTIONS, GOOGLE_ACTION_LABEL } from '../../../../utils/constants';
import { useLocation } from 'react-router-dom';
import { isWebviewUnsupportedByGoogleOAuth } from 'google-oauth-webview';
import { Button, WebviewCallout } from '../../../../components';
import { productAnalytics } from '../../../../external-services';

export default function Ga4ConnectorCard() {
  const getGa4ConnectURL = useGetGa4ConnectURL();
  const { state } = useLocation();

  async function connectToGa4() {
    try {
      const googleSignInURL = await getGa4ConnectURL();
      productAnalytics.sendEvent('start_connect_to_ga4');
      localStorage.setItem(GOOGLE_ACTION_LABEL, GOOGLE_ACTIONS.CONNECT_TO_GA4);
      window.location.href = googleSignInURL;
    } catch (error) {
      console.error(error);
    }
  }

  return (
    <Card>
      <div className="space-y-6 py-6 text-center">
        <h4 className="text-tremor-metric">Let's get started</h4>
        <p className="text-tremor-default text-tremor-content dark:text-dark-tremor-content">
          Connect your Google Analytics account to get access to all your web analytics.
        </p>
        {isWebviewUnsupportedByGoogleOAuth(navigator.userAgent) && (
          <div className="flex justify-center text-left">
            <WebviewCallout messageType="connect-to-ga4" />
          </div>
        )}
        {state?.connectionToGa4Failed && (
          <p className="text-tremor-default text-red-700">
            An error occurred while connecting to Google Analytics.
          </p>
        )}
        <Button variant="primary" icon={RiPlugLine} onClick={connectToGa4}>
          Connect to Google Analytics 4
        </Button>
      </div>
    </Card>
  );
}
