import { Crisp } from 'crisp-sdk-web';
import { isDemoMode } from '../configuration';
import { ExternalService } from './external-service.interface';

const CRISP_WEBSITE_ID = process.env.REACT_APP_CRISP_WEBSITE_ID;

export const liveChat = {
  name: 'Crisp',
  isEnabled: () => {
    return CRISP_WEBSITE_ID !== undefined;
  },
  init() {
    // Only here for typing purposes
    if (!CRISP_WEBSITE_ID) {
      return;
    }

    Crisp.configure(CRISP_WEBSITE_ID);
  },
  setUser(user: { id: number; email: string } | null) {
    if (isDemoMode()) {
      console.log(`Demo mode is enabled. Skipping setting user data for ${this.name}.`);
      return;
    }

    if (!user) {
      // TODO: reset user data (log out)
      return;
    }

    Crisp.user.setEmail(user.email);
    Crisp.session.setData({ userId: user.id });
  },
} satisfies ExternalService;
