import { PropsWithChildren } from 'react';

export default function TableCell({
  children,
  bold,
  small,
  textAlign,
  dark,
  fullWidth,
}: PropsWithChildren & {
  bold?: boolean;
  small?: boolean;
  textAlign?: 'left' | 'center' | 'right';
  dark?: boolean;
  fullWidth?: boolean;
}) {
  const padding = small ? 'px-2 py-2' : 'px-3 py-4';
  const align =
    textAlign === 'left'
      ? 'text-left'
      : textAlign === 'right'
        ? 'text-right'
        : 'text-center';

  return (
    <td
      className={`whitespace-nowrap ${fullWidth ? 'w-full' : ''} ${padding} ${align} text-sm ${bold ? 'font-medium' : ''} ${dark ? 'text-gray-900' : 'text-gray-500'}`}
    >
      {children}
    </td>
  );
}
