import { useAuth } from '../../../../auth';
import { useMakePostRequest } from '../../../requests.hooks';

export function useSignOut(): () => Promise<void> {
  const { makePostRequest } = useMakePostRequest();
  const { refreshCurrentUser } = useAuth();

  return async () => {
    await makePostRequest(`/api/auth/sign-out`);
    await refreshCurrentUser();
  };
}
