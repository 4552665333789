import { User } from '../../../../requests/api/back-office.requests';

export default function SubscriptionStatus({
  status,
}: {
  status: User['status']['subscription'];
}) {
  switch (status) {
    case 'trialing':
      return <span className="text-green-600">Trial</span>;
    case 'trial ended':
      return <span className="text-yellow-600">Trial ended</span>;
    case 'active':
      return <span className="text-green-600">Active</span>;
    case 'pending cancellation':
      return <span className="text-yellow-600">Pending cancellation</span>;
    case 'cancelled':
      return <span className="text-red-600">Cancelled</span>;
    default:
      return null;
  }
}
