import { Callout, Card } from '@tremor/react';
import { CardTitle, NumberValue, SkeletonLoader } from '../../../../components';
import { useBlock } from '../../traffic-section/common';
import { Comparison, DateRange, Filter } from '../../../../requests/api/traffic.requests';

interface ConversionKeyMetricsCardProps {
  websiteId: string;
  dateRange: DateRange;
  filters: Filter[];
  comparison: Comparison | undefined;
}

export default function ConversionKeyMetricsCard({
  websiteId,
  dateRange,
  filters,
  comparison,
}: ConversionKeyMetricsCardProps) {
  const block = useBlock({
    blockName: 'conversionKeyMetrics',
    websiteId,
    dateRange,
    filters,
    comparison,
    queryKey: 'conversionKeyMetricsBlock',
  });

  return (
    <Card>
      <CardTitle title="Overview" />
      {block.isPending && <SkeletonLoader className="mt-4 h-16" />}
      {block.isError && (
        <Callout title="An error has occurred" color="red" className="mt-4">
          An error occurred while retrieving the data. Try refreshing the page.
        </Callout>
      )}
      {block.isSuccess && (
        <div className="mt-4 flex justify-around gap-6 lg:flex-col">
          <div className="space-y-1">
            <div className="text-center text-tremor-metric text-tremor-brand">
              <NumberValue
                values={block.data.totalConvertedVisitors}
                isLoading={block.isRefetching}
                showComparisonBadgeUponComparison
              />
            </div>
            <div className="text-center text-tremor-label text-tremor-content">
              Converted visitors
            </div>
          </div>
        </div>
      )}
    </Card>
  );
}
