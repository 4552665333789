import { useAuth } from '../../../../auth';
import { useMakeGetRequest } from '../../../requests.hooks';

export function useGetGoogleSignInURL(): () => Promise<string> {
  const { makeGetRequest } = useMakeGetRequest();

  return async () => {
    const { data } = await makeGetRequest('/api/auth/sign-in/google/url');
    return data.consentPageUrl;
  };
}

export function useHandleGoogleSignInRedirection(): ({
  searchParams,
}: {
  searchParams: string;
}) => Promise<void> {
  const { makeGetRequest } = useMakeGetRequest();
  const { refreshCurrentUser } = useAuth();

  return async ({ searchParams }) => {
    const { data } = await makeGetRequest(
      `/api/auth/sign-in/google/callback${searchParams}`,
    );
    await refreshCurrentUser();
    return data;
  };
}
