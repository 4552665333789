import { CurrentUser } from '../../auth';
import { useMakeGetRequest } from '../requests.hooks';

export function useGetCurrentUser(): () => Promise<CurrentUser | null> {
  const { makeGetRequest } = useMakeGetRequest();

  return async () => {
    try {
      const { data } = await makeGetRequest<CurrentUser>('/api/users/me');
      return data;
    } catch (error: any) {
      if (error.response?.status === 401) {
        return null;
      }
      throw error;
    }
  };
}
