import { Callout } from '@tremor/react';
import { getWebviewOwner } from 'google-oauth-webview';
import { getBrowserName, getProhibitionMessage } from './webview-callout.utils';

export default function WebviewCallout({
  messageType,
}: {
  messageType: 'sign-in' | 'sign-up' | 'connect-to-ga4';
}) {
  const webviewOwner = getWebviewOwner(navigator.userAgent);
  return (
    <Callout color="yellow" title="Unsupported browser">
      It looks like you're opening this page in{' '}
      <strong>{getBrowserName(webviewOwner)}</strong>.
      <br />
      <br />
      {getProhibitionMessage(messageType)}
      <br />
      <br />
      Please open this page in your default browser to continue.
    </Callout>
  );
}
