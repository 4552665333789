import { CardTitle } from '../card-title';
import MaxItemsPerPageSelect from './MaxItemsPerPageSelect';
import Tabs from './Tabs';

export default function CardHeader<T extends string>({
  title,
  isAboveTable = false,
  tabs,
  selectedTab,
  onSelectedTabChange,
  maxItemsPerPage,
  onMaxItemsPerPageChange = () => {},
}: {
  title: string;
  isAboveTable?: boolean;
  tabs: Record<T, string>;
  selectedTab: T;
  onSelectedTabChange: (tab: T) => void;
  maxItemsPerPage?: number;
  onMaxItemsPerPageChange?: (maxItemsPerPage: number) => void;
}) {
  return (
    <div
      className={`flex flex-col items-center gap-2 sm:flex-row ${isAboveTable ? 'px-1' : ''}`}
    >
      <div className="flex grow items-center">
        <CardTitle title={title} />
        <div className="hidden grow sm:block"></div>
        {maxItemsPerPage !== undefined && (
          <div className="absolute right-3 sm:static sm:right-auto">
            <MaxItemsPerPageSelect
              maxItemsPerPage={maxItemsPerPage}
              onMaxItemsPerPageChange={onMaxItemsPerPageChange}
            />
          </div>
        )}
      </div>
      <Tabs
        tabs={tabs}
        selectedTab={selectedTab}
        onSelectedTabChange={onSelectedTabChange}
      />
    </div>
  );
}
