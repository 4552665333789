export function formatSubscriptionDate({
  date,
  nullDate = '[unknown date]',
  timeZone,
}: {
  date: string | null;
  nullDate?: string;
  timeZone?: string;
}): string {
  if (!date) {
    return nullDate;
  }

  const dateFormatter = new Intl.DateTimeFormat(process.env.REACT_APP_INTL_LANGUAGE, {
    day: 'numeric',
    month: 'short',
    year: 'numeric',
    timeZone,
  });
  return dateFormatter.format(new Date(date));
}
