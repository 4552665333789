import { AbstractSubscriptionCallbackPage } from '../../components';

export default function UnsubscriptionCallbackPage() {
  return (
    <AbstractSubscriptionCallbackPage
      description="Your recurring payment has been cancelled. Cancelling your subscription on Linito..."
      expectedSubscriptionStatus="pending cancellation"
    />
  );
}
