import { RiCheckboxCircleFill } from '@remixicon/react';
import { Card, List, ListItem, Divider } from '@tremor/react';
import { useAuthenticatedUser } from '../../../auth';
import { Button, CardTitle } from '../../../components';
import { getSubscriptionDescription } from '../../common';
import { Paddle, initializePaddle } from '@paddle/paddle-js';
import { useState, useEffect } from 'react';
import { useResubscribe, useUnsubscribe } from '../../../requests/api/account.requests';
import { useNavigate } from 'react-router-dom';
import UnsubscriptionDialog from './UnsubscriptionDialog';
import { productAnalytics } from '../../../external-services';

const features = [
  {
    id: 1,
    name: 'Unlimited websites',
  },
  {
    id: 2,
    name: 'Unlimited visitors',
  },
  {
    id: 3,
    name: 'Website analytics',
  },
];

export default function SubscriptionCard() {
  const { currentUser, refreshCurrentUser } = useAuthenticatedUser();

  const [paddle, setPaddle] = useState<Paddle>();
  const [isUnsubscribeDialogOpen, setIsUnsubscribeDialogOpen] = useState(false);

  const navigate = useNavigate();

  const unsubscribe = useUnsubscribe();
  const resubscribe = useResubscribe();

  const openUnsubscribeDialog = () => setIsUnsubscribeDialogOpen(true);
  const closeUnsubscribeDialog = () => setIsUnsubscribeDialogOpen(false);

  const unsubscribeAndRedirect = async () => {
    await unsubscribe();
    productAnalytics.sendEvent('unsubscribe');
    navigate('/unsubscription-callback');
  };
  const resubscribeAndRedirect = async () => {
    await resubscribe();
    productAnalytics.sendEvent('resubscribe');
    navigate('/resubscription-callback');
  };

  useEffect(() => {
    const isPaddleSandBox = process.env.REACT_APP_PADDLE_IS_SANDBOX === 'true';
    const paddleClientSideToken = process.env.REACT_APP_PADDLE_CLIENT_SIDE_TOKEN ?? '';

    initializePaddle({
      environment: isPaddleSandBox ? 'sandbox' : 'production',
      token: paddleClientSideToken,
      eventCallback(event) {
        if (event.name === 'checkout.completed') {
          productAnalytics.sendEvent('subscribe', {
            plan: 'Pro',
          });
          refreshCurrentUser();
        }
      },
    }).then(setPaddle);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function openCheckout() {
    const paddlePriceId = process.env.REACT_APP_PADDLE_PRICE_ID;
    if (!paddlePriceId) {
      throw new Error('Paddle price ID is not set');
    }

    if (!paddle) {
      throw new Error('Paddle is not initialized');
    }

    productAnalytics.sendEvent('start_subscribe', {
      plan: 'Pro',
    });

    paddle.Checkout.open({
      settings: {
        // Whether the user can change their email once on the checkout.
        allowLogout: false,
        successUrl: `${window.location.origin}/subscription-callback`,
      },
      customData: {
        userId: currentUser.id,
      },
      items: [
        {
          priceId: paddlePriceId,
          quantity: 1,
        },
      ],
      customer: {
        email: currentUser.email,
      },
    });
  }

  const isActive = currentUser.status.subscription === 'active';

  const isPendingCancellation =
    currentUser.status.subscription === 'pending cancellation';

  const isTrial =
    currentUser.status.subscription === 'trialing' ||
    currentUser.status.subscription === 'trial ended';

  return (
    <Card>
      <CardTitle title="Subscription" />
      <div className="grid grid-cols-1 gap-6 md:grid-cols-2">
        <p className="mt-4 text-tremor-default leading-6 text-tremor-content">
          {getSubscriptionDescription({
            status: currentUser.status.subscription,
            subscription: currentUser.subscription,
          })}
        </p>
        <div className="rounded-tremor-default border border-tremor-border bg-tremor-background-muted p-6">
          <div className="flex items-start justify-between space-x-6">
            <h3 className="text-xl font-semibold text-tremor-content-strong">Pro</h3>
            <div>
              <p className="flex items-baseline justify-end">
                <span className="text-tremor-metric font-semibold text-tremor-content-strong">
                  14 €
                </span>
                <span className="text-tremor-default text-tremor-content">/mo</span>
              </p>
              <p className="text-right text-tremor-label text-tremor-content">
                + taxes if applicable
              </p>
            </div>
          </div>
          <List className="mt-4 divide-y-0 text-tremor-content-emphasis">
            {features.map(item => (
              <ListItem key={item.id} className="justify-start space-x-2 py-2.5">
                <RiCheckboxCircleFill
                  className="size-5 shrink-0 text-tremor-brand"
                  aria-hidden={true}
                />
                <span>{item.name}</span>
              </ListItem>
            ))}
          </List>
          <Divider />
          {isTrial && (
            <Button variant="primary" block onClick={openCheckout}>
              Subscribe
            </Button>
          )}
          {isActive && (
            <>
              <Button variant="danger" block onClick={openUnsubscribeDialog}>
                Cancel subscription
              </Button>
              <UnsubscriptionDialog
                isOpen={isUnsubscribeDialogOpen}
                onCancel={closeUnsubscribeDialog}
                onSubmit={() => {
                  closeUnsubscribeDialog();
                  unsubscribeAndRedirect();
                }}
              />
            </>
          )}
          {isPendingCancellation && (
            <Button variant="primary" block onClick={resubscribeAndRedirect}>
              Re-subscribe
            </Button>
          )}
          {isTrial && (
            <p className="mt-4 text-right text-tremor-label text-tremor-content">
              Your subscription will be billed monthly. You can cancel it at any time on
              this page.
            </p>
          )}
        </div>
      </div>
    </Card>
  );
}
