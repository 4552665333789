import { Card } from '@tremor/react';
import { useListAllUsers } from '../../requests/api/back-office.requests';
import { useQuery } from '@tanstack/react-query';
import { formatNumber } from '../../utils';
import { UserList } from './common';
import { CardTitle, PageContainer } from '../../components';

export default function BackOfficePage() {
  const listAllUsers = useListAllUsers();

  const allUsers = useQuery({
    queryKey: ['allUsers'],
    queryFn: () => listAllUsers(),
  });

  return (
    <PageContainer>
      <Card>
        <div className="flex items-center">
          <CardTitle title="Users" />
          <div className="grow"></div>
          {allUsers.isSuccess && (
            <div className="flex space-x-4 text-tremor-default">
              <div>
                <span className="font-bold">Total users: </span>
                {formatNumber(allUsers.data?.length)}
              </div>
              <div>
                <span className="font-bold">Total users synchro: </span>
                {formatNumber(
                  allUsers.data?.filter(user => user.status.account === 'ga4-connected')
                    .length,
                )}
              </div>
            </div>
          )}
        </div>
        <div className="overflow-x-auto">
          {allUsers.isSuccess && <UserList users={allUsers.data} />}
        </div>
      </Card>
    </PageContainer>
  );
}
