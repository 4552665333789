import { PropsWithChildren } from 'react';
import { Header } from './header';
import Footer from '../Footer';

export default function PageContainer({ children }: PropsWithChildren) {
  return (
    <div className="mx-auto flex h-full flex-col space-y-6 px-4 pt-10 xl:container md:px-20 md:pt-16 lg:px-4 xl:px-20">
      <div className="pb-2">
        <Header />
      </div>
      {children}
      <div className="grow"></div>
      <Footer />
    </div>
  );
}
